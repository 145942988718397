import { Injectable } from '@angular/core';
import {
  createLoadTask,
  addNotificationItem,
  setupErrorNotification,
  getStaticContent,
  createWarningNotification
} from '@inmarsat-itcloudservices/ui';
import { Actions } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import { HierarchyApiService } from '../../api/hierarchy/hierarchy-api.service';
import { staticContent } from '../../app.constants';
import * as HierarchyActions from './hierarchy-tree.actions';

@Injectable()
export class HierarchyEffects {
  public hierarchyList$ = createLoadTask(
    ({ corporateGroupId, entityType }) => this.hierarchyService.getHierarchyList(corporateGroupId, entityType),
    {
      actions: this.actions$,
      ofType: HierarchyActions.loadHierarchy,
      onSuccess: HierarchyActions.loadHierarchySuccess,
      onError: (error, { type }) =>
        error instanceof HttpErrorResponse && error.status === 404
          ? addNotificationItem(
              createWarningNotification(getStaticContent('hierarchy-tree.corporate_group_not_found', staticContent))
            )
          : addNotificationItem(setupErrorNotification(error, type))
    }
  );

  constructor(
    private readonly hierarchyService: HierarchyApiService,
    private readonly actions$: Actions
  ) {}
}
