import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UsersApiService } from '@app/api/users/users-api.service';
import { OKTA_TOKEN_KEY, ROUTES } from '@app/app.constants';
import { environment } from '@env/environment';
import {
  concatSpinner,
  getCurrentUser,
  IAppPermission,
  loadCurrentUser,
  loadCurrentUserSucccess,
  login,
  logout,
  userNotAssignedError,
  validActions,
  validResources
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthAction, AuthResource } from '@shared/models/auth.model';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { IState } from '..';

@Injectable()
export class AuthEffects {
  /**
   * Okta provides a promise to get the initial authentication status, and an observable to listen for updates.
   * The observable doesn't return an initial status, hence the merge.
   */
  public authenticated = this.authStateService.authState$;

  /**
   * Listen to async Okta authentication APIs, and update the store with user information.
   */
  public authenticationUser$ = createEffect(
    () =>
      this.authenticated.pipe(
        withLatestFrom(this.store.select(getCurrentUser)),
        filter(([, user]) => !user),
        switchMap(async ([authState]) => {
          this.store.dispatch(loadCurrentUser({ isAuthenticated: authState.isAuthenticated }));
        })
      ),
    { dispatch: false }
  );

  public loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrentUser),
      filter((action) => action.isAuthenticated),
      concatSpinner(() =>
        forkJoin({
          user: this.usersApiService.getCurrentUserDetail(),
          permissions: this.usersApiService.getUserAccessPermissions().pipe(
            map((permissions) => {
              // Only store the permissions for resources the app cares about.
              return permissions
                .filter((p) => validResources(AuthResource).has(p.resource))
                .map((p) => ({
                  ...p,
                  actions: p.actions.filter((a) => validActions(AuthAction).has(a))
                })) as IAppPermission[];
            })
          )
        }).pipe(map(loadCurrentUserSucccess))
      )
    )
  );

  /**
   * Listen to login actions & call the Okta loginRedirect API.
   *
   * This will take the user to a login screen & then redirect once complete.
   */
  public login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        map((action) => {
          return this.oktaAuth.signInWithRedirect({ originalUri: action.redirectURl });
        })
      ),
    { dispatch: false }
  );

  /**
   * The only manual part of our flow.
   * Listen to user-triggered logout actions, call the async Okta logout API, wait for a response.
   */
  public logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          (async () => {
            this.cookies.deleteAll('/');
            // Remove localstorage okta token of other applications, doing this will log the other applications out too
            for (const prop in localStorage) {
              if (localStorage.hasOwnProperty(prop) && prop !== OKTA_TOKEN_KEY) {
                localStorage.removeItem(prop);
              }
            }
            await this.oktaAuth.signOut({
              postLogoutRedirectUri: environment.okta.postLogoutRedirectUri
            });
          })().then(
            () => {},
            () => {}
          );
        })
      ),
    { dispatch: false }
  );

  public errorRedirects = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userNotAssignedError),
        distinctUntilChanged((a, b) => JSON.stringify(a.error) === JSON.stringify(b.error)),
        tap((error: any) => {
          const errorDescription = error?.message || error?.error_description || error?.errorSummary;
          const errorCode = error?.errorCode || error?.statusText;

          this.zone.run(() => {
            void this.router.navigate([ROUTES.ERROR], {
              queryParams: { code: errorCode, description: errorDescription }
            });
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    @Inject(OKTA_AUTH) private readonly oktaAuth: OktaAuth,
    private readonly actions$: Actions,
    public store: Store<IState>,
    private readonly router: Router,
    private readonly cookies: CookieService,
    private readonly usersApiService: UsersApiService,
    private readonly zone: NgZone,
    private readonly authStateService: OktaAuthStateService
  ) {}
}
