import { Injectable } from '@angular/core';
import {
  addNotificationItem,
  concatSpinner,
  createLoadTask,
  createSuccessNotification,
  getStaticContent
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { UploadApiService } from '@app/api/upload/upload-api.service';
import { staticContent } from '@app/app.constants';
import * as UploadFileActions from './upload-file.actions';

@Injectable()
export class UploadEffects {
  public uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadFileActions.uploadFile),
      concatSpinner(({ payload }) =>
        this.uploadService
          .uploadFile(payload)
          .pipe(
            switchMap(() => [
              addNotificationItem(createSuccessNotification(getStaticContent(`bulk_update.message`, staticContent)))
            ])
          )
      )
    )
  );

  public uploadFileAudit$ = createLoadTask(({ fileId }) => this.uploadService.getUploadFileAudit(fileId), {
    actions: this.actions$,
    ofType: UploadFileActions.loadUploadFileAudit,
    onSuccess: UploadFileActions.loadUploadFileAuditSuccess
  });

  constructor(
    private readonly uploadService: UploadApiService,
    private readonly actions$: Actions
  ) {}
}
