import { IPagingQueryParams, IReferenceOption, ISortQuery } from '@inmarsat-itcloudservices/ui';
import { IAddress } from './address.model';
import { IContact } from './contact.model';
import { SalesTeamRoles } from './sales.model';

export interface IAccountBase {
  accountName: string;
  accountCode: string;
}

/*
 * Account partner fields used for INMA, INM6 and IM17
 */
export interface IAccountPartner {
  contractParty?: string;
  managementParty?: string;
  shipOwner?: string;
  dealerCode?: string;
}

export interface IAccountDetails extends IAccountBase, IAccountPartner {
  cleName: string;
  cleCode: string;
  invoiceReport: string;
  id: string;
  post: boolean;
  minimumBillingAmount: number;
  invoiceReportLevel: string;
  invoicePreference: string;
  digitalCollection?: string;
  paymentMethod: string;
  paymentTerm: string;
  primaryBilling: boolean;
  legacyARCode: string;
  vatNumber: string;
  pricingRule: string;
  pricingRuleStartDate: string;
  endDate: string;
  comments: string;
  currency: string;
  customerType: string;
  customerSubtypes: string[];
  salesOrgs: ISalesOrg[];
  address: IAddress;
  billingAddress: string;
  accountNumber: string;
  type: string;
  name: string;
  startDate: Date;
  createdDate: Date;
  createdBy: string;
  createdSystem: string;
  lastModifiedDate: Date;
  lastModifiedBy: string;
  lastRefreshedOn?: string;
  lastRefreshedAsCreateOn?: string;
  modifiedSystem: string;
  systemAccountIds: Record<string, unknown>;
  salesSupportManagerName: string;
  salesSupportManagerEmail: string;
  salesManagerName: string;
  salesManagerEmail: string;
  salesVPName: string;
  accountGroup: AccountGroup;
  legalEntityCode: string;
  legalEntityName: string;
  systemRefIdMCF?: string;
  systemRefIdBCM?: string;
  systemRefIdSV?: string;
  salesSupportId: string;
  salesManagerId: string;
  salesLeadId: string;
  salesManager: boolean;
  salesSupport: boolean;
  salesVP: boolean;
  corporateGroupCode: string;
  corporateGroupName: string;
  taxExempt: boolean;
  usfExempt: boolean;
  domesticReverseCharge: boolean;
  taxComments: string;
  contractParty?: string;
  managementParty?: string;
  shipOwner?: string;
  dealerCode?: string;
  billingContact: string;
  contact: IContact;
  status?: AccountStatus;
  dpId: string;
  complianceBlock: boolean;
  shippingDetails?: IShippingDetails[];
  salesVPEmail: string;
}

export interface IAccountReviewPayload {
  dpId?: string;
  contractParty?: string;
  managementParty?: string;
  shipOwner?: string;
  dealerCode?: string;
  salesSupportManagerName?: string;
  salesSupportManagerEmail?: string;
  salesManagerName?: string;
  salesManagerEmail?: string;
  salesVPName?: string;
  salesVPEmail?: string;
  accountGroup?: string;
  legalEntityCode?: string;
  salesSupportId?: string;
  salesManagerId?: string;
  salesLeadId?: string;
  salesManager?: boolean;
  salesSupport?: boolean;
  salesVP?: boolean;
  isEdit?: boolean;
  taxExempt?: boolean;
  post?: boolean;
  salesOrgs?: ISalesOrg[];
  minimumBillingAmount?: number;
  invoiceReportLevel?: string;
  invoicePreference?: string;
  digitalCollection?: string;
  paymentMethod?: string;
  paymentTerms?: string;
  primaryBilling?: boolean;
  legacyARCode?: string;
  vatNumber?: string;
  comments?: string;
  currency?: string;
  customerType?: string;
  customerSubtypes?: string;
  accountNumber?: string;
  id?: string;
  givenName?: string;
  surname?: string;
  prefix?: string;
  suffix?: string;
  jobTitle?: string;
  phone?: string;
  email?: string;
  alternateEmail?: string;
  mobile?: string;
  fax?: string;
}

export interface ISalesOrg {
  postingAccount?: string;
  currency?: string;
  paymentTerms?: string;
  salesOffice?: string;
  code?: string;
  name?: string;
  systemRefIdMCF?: string;
  systemRefIdBCM?: string;
  systemRefIdSV?: string;
}

export interface IAccountQuery extends ISortQuery, IPagingQueryParams {
  accountName?: string;
  accountCode?: string;
  active?: boolean;
  multiSearch?: boolean;
  supportId?: string;
  salesManagerId?: string;
  salesLeadId?: string;
  legalEntityCode?: string;
  legalEntityName?: string;
  customerType?: string;
  customerSubtypes?: string;
  businessUnit?: string;
  status?: AccountStatus;
  primaryBilling?: boolean;
  post?: boolean;
  accountGroup?: string;
  legacyARCode?: string;
  createdBySelf?: boolean; // service filter out createdBy = OKta's username
}

export interface IAccountSalesInfo {
  roleType: SalesTeamRoles;
  salesCode: string;
  salesName: string;
}

export interface IAccountPartnerInfo {
  contractParty?: string;
  contractPartyName?: string;
  managementParty?: string;
  managementPartyName?: string;
  shipOwner?: string;
  shipOwnerName?: string;
  dealerCode: string;
  dealerCodeName?: string;
  postingAccount?: string;
  postingAccountName?: string;
  legalEntityCode?: string;
}

export enum AccountSearchOptions {
  CustomerType = 'Customer type',
  BusinessUnit = 'BU',
  AccountName = 'Name',
  AccountCode = 'Account code',
  LegacyARCode = 'Legacy AR code'
}

export const AccountGroupOptions: IReferenceOption[] = [
  {
    label: 'INMA - Retail Customer I3/I4(BGAN/FB/GSPS/SB)/I5(GX)',
    value: 'INMA'
  },
  {
    label: 'INM7 - Retail Customer I5(FX)',
    value: 'INM7'
  },
  {
    label: 'INM1 - Wholesale Customer',
    value: 'INM1'
  },
  {
    label: 'INDE - Dealer',
    value: 'INDE'
  }
];

export interface IAddAccountPayload {
  legalEntityCode?: string;
  name?: string;
  accountGroup?: AccountGroup;
  customerType?: string;
  customerSubtypes?: string;
  post?: boolean;
  status?: string;
  invoiceReportLevel?: string;
  minimumBillingAmount?: number;
  primaryBilling?: boolean;
  type?: string;
  comments?: string;
  vatNumber?: string;
  paymentTerms?: string;
  currency?: string;
  isEdit?: boolean;
  salesOrgs?: ISalesOrg[];
}

export interface IAddSalesTeamMemberPayload {
  salesSupportId?: string;
  salesSupportManagerName?: string;
  salesManagerId?: string;
  salesManagerName?: string;
  salesLeadId?: string;
  salesVPName?: string;
  accountNumber?: string;
  salesSupportManagerEmail?: string;
  salesManagerEmail?: string;
  salesVPEmail?: string;
  status?: string;
}

export interface IAccountDealerPayload {
  dealerCode: string;
  accountNumber: string;
}

export interface IShippingDetails {
  id?: string;
  address?: string;
  contact?: string;
  status?: IShippingAddressAndContactStatus;
}

export interface IShippingAddressAndContact {
  [key: string]: {
    shippingAddress?: IAddress;
    shippingContact?: IContact;
  };
}

export interface IMoveAccountPayload {
  // Move AR account
  currentLegalEntityCode: string;
  accountNumber: string;
  newLegalEntityCode: string;
}

export interface IMoveBPAccountPayload {
  currentAccountCode: string;
  accountCode: string;
  newAccountCode: string;
  newCLE?: string;
}

export interface IMoveAccountResponse {
  childAccountsMoved: number;
  sitesMoved: number;
  addressMoved: number;
  contactsMoved: number;
}

export interface IUpdateCloneAccountPayload {
  accountNumber: string;
  status?: AccountStatus;
}

export enum EditAccountSection {
  SALES_TEAM = 'SALES TEAM',
  TAXEXEMPTIONS = 'TAX EXEMPTIONS',
  ACCOUNTINFO = 'ACCOUNT INFO',
  SALESORG = 'SALES ORG',
  END_DATE = 'END DATE'
}

export enum AccountStatus {
  ACTIVE = 'Active',
  DRAFT = 'Draft',
  INCOMPLETE = 'Incomplete',
  INACTIVE = 'Inactive',
  VOID = 'Void'
}

export enum AccountGroup {
  IM15 = 'IM15', //AR
  IM16 = 'IM16', //AR
  IM17 = 'IM17', //BP
  INDE = 'INDE', //AR
  INM1 = 'INM1', //Both
  INM2 = 'INM2', //AR
  INM5 = 'INM5', //AR
  INM6 = 'INM6', //BP
  INM7 = 'INM7', //AR
  INMA = 'INMA', //AR
  INMB = 'INMB', //BP
  INMI = 'INMI' //AR
}

export const AccountGroupDescription: IReferenceOption[] = [
  {
    label: 'IM15 - Main_Inmarsat Maritime Cust - GW',
    value: 'IM15'
  },
  {
    label: 'IM16 - Sub_Inmarsat Group Cust - GW',
    value: 'IM16'
  },
  {
    label: 'IM17 - Inmarsat Vessel - GW',
    value: 'IM17'
  },
  {
    label: 'INDE - Inmarsat Agent',
    value: 'INDE'
  },
  {
    label: 'INM1 - Inmarsat: Space Segment',
    value: 'INM1'
  },
  {
    label: 'INM2 - Inmarsat: Miscellaneous',
    value: 'INM2'
  },
  {
    label: 'INM5 - Inmarsat GSPS Customers',
    value: 'INM5'
  },
  {
    label: 'INM6 - Inmarsat Vessel',
    value: 'INM6'
  },
  {
    label: 'INM7 - Inmarsat Maritime Customer',
    value: 'INM7'
  },
  {
    label: 'INMA - Inmarsat - AR Account',
    value: 'INMA'
  },
  {
    label: 'INMB - Inmarsat - Billing Profile',
    value: 'INMB'
  },
  {
    label: 'INMI - Inmarsat - Interco. customers',
    value: 'INMI'
  }
];

export const AccountSalesMap = new Map([
  [SalesTeamRoles.SALESSUPPORT, 'salesSupportId'],
  [SalesTeamRoles.SALESMANAGER, 'salesManagerId'],
  [SalesTeamRoles.SALESLEAD, 'salesLeadId']
]);

export enum MoveBPAccountFields {
  ContractParty = 'contractParty',
  ManagementParty = 'managementParty',
  ShipOwner = 'shipOwner',
  DealerCode = 'dealer'
}

export enum AccountDetailsPageTabName {
  AccountInfo = 'accountInfo',
  SalesInfo = 'salesInfo',
  SalesOrgs = 'salesOrgs',
  PricingLink = 'pricingLink',
  OutboundFeed = 'outboundFeed',
  Shipping = 'shipping'
}

export enum AccountDetailsAdditionalTabName {
  ATTACHMENT = 'Attachment',
  CHANGE_REQUEST = 'Change request'
}

export enum IShippingAddressAndContactStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive' //Used for soft delete shippingInfo
}
