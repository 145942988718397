import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IPageData, STATIC_CONTENT_CONTEXT, STATIC_CONTENT_PAYLOAD } from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { DEFAULT_PAGE_SIZE, ROUTES, staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import { getSelectedCLEDetails } from '@shared-store/cle/cle.selectors';
import { getCorporateGroupsPage } from '@shared-store/corporate-groups/corporate-groups.selectors';
import { ICLEBaseDetails, Status } from '@shared/models/cle.model';
import { ICorporateGroups } from '@shared/models/corporate-groups.model';
import * as CorporateGroupsActions from '@shared-store/corporate-groups/corporate-groups.actions';
import * as CLEActions from '@shared-store/cle/cle.actions';

@Component({
  selector: 'inm-add-reassign-corporate-group-modal',
  templateUrl: './add-reassign-corporate-group-modal.component.html',
  styleUrls: ['./add-reassign-corporate-group-modal.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'customers.cle.modal'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class AddReassignCorporateGroupModalComponent implements OnInit, OnDestroy {
  public corporateGroupCode: string;

  public assignMode = true;

  @Input('addReassignCorporateGroup')
  public set addReassignCorporateGroup(value: any) {
    this.corporateGroupCode = value.addReassignCorporateGroup;
  }

  public corporateGroupList: IPageData<ICorporateGroups>;

  public modalSubscription = new Subscription();

  public associatedCorporateGroup;

  public readonly corporateGroupCtrl = new UntypedFormControl(null, Validators.required);

  public addReassignFormGroup = new UntypedFormGroup({
    corporateGroup: this.corporateGroupCtrl
  });

  public legalEntityCode: string;

  public cleDetails: ICLEBaseDetails;

  constructor(
    private readonly router: Router,
    public bsModalRef: BsModalRef,
    private readonly store: Store<IState>
  ) {
    this.store.select(getCorporateGroupsPage).subscribe((corporateGroupData) => {
      let updatedCorporateGroupData: IPageData<ICorporateGroups>;
      if (corporateGroupData.items) {
        updatedCorporateGroupData = {
          ...corporateGroupData,
          items: corporateGroupData.items.map((value) => {
            return {
              ...value,
              legalEntityDetail: `${value.legalEntityCode} , ${value.details.legalName}`
            };
          })
        };
      }
      this.corporateGroupList = updatedCorporateGroupData;
    });
  }

  public ngOnInit(): void {
    this.modalSubscription.add(
      this.store.select(getSelectedCLEDetails).subscribe((selectedCleDetails) => {
        if (selectedCleDetails?.baseDetail) {
          this.legalEntityCode = selectedCleDetails.baseDetail.legalEntityCode;
          this.cleDetails = selectedCleDetails.baseDetail;
          this.associatedCorporateGroup = selectedCleDetails.associatedCorporateGroup;
          if (this.corporateGroupCode && this.associatedCorporateGroup) {
            this.initializeCorporateGroupValue();
            if (this.corporateGroupList.items) {
              this.corporateGroupList = {
                ...this.corporateGroupList,
                items: this.corporateGroupList.items.map((corporateGroup) => {
                  return {
                    ...corporateGroup,
                    legalEntityDetail: `${corporateGroup.legalEntityCode} , ${corporateGroup.details.legalName}`
                  };
                })
              };
            }
          }
        }
      })
    );

    if (this.corporateGroupCode) {
      this.assignMode = false;
      this.setDefaultCorporateGroupValue();
    } else {
      this.store.dispatch(CorporateGroupsActions.load({ offset: 0, limit: DEFAULT_PAGE_SIZE }));
    }
  }

  public assignCorporateGroup(): void {
    if (this.addReassignFormGroup.valid) {
      const payload = this.addReassignFormGroup.controls.corporateGroup.value;
      const payloadToSend = {
        ...(this.cleDetails.status === Status.Incomplete &&
          this.cleDetails.details.creditLimit && {
            status: Status.Draft
          }),
        parentCode: payload.legalEntityCode,
        parentName: payload.details.legalName,
        legalEntityCode: this.legalEntityCode,
        collectorCode: payload.collectorCode
      };
      this.store.dispatch(CLEActions.assignCorporateGroup(payloadToSend));
      // update parentCode in the url
      void this.router.navigate(['/', ROUTES.CUSTOMERS, ROUTES.CLE, this.legalEntityCode], {
        queryParams: {
          parentId: payload.legalEntityCode
        }
      });
    } else {
      this.addReassignFormGroup.markAllAsTouched();
    }
  }

  public searchCorporateGroup(search: string): void {
    this.store.dispatch(
      CorporateGroupsActions.load({
        corporateGroups: search,
        legalEntityCode: search,
        offset: 0,
        limit: 20,
        active: true,
        multiSearch: true
      })
    );
  }

  public ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  private setDefaultCorporateGroupValue(): void {
    this.addReassignFormGroup.patchValue({
      corporateGroup: this.corporateGroupList.items[0]
    });
  }

  private initializeCorporateGroupValue(): void {
    this.corporateGroupList = {
      loading: false,
      items: this.associatedCorporateGroup ? [this.associatedCorporateGroup] : [],
      totalCount: 0,
      currentPage: 0
    };
  }
}
