import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const API_URLS = [
  environment.api.cmd_url,
  environment.api.user_url,
  environment.api.sales_url,
  environment.api.terminaldata_url,
  environment.api.pricingAgreement_url,
  environment.api.bulkUpload_url,
  environment.api.attachment_url,
  environment.api.outboundFeed_url,
  environment.api.hierarchyTree_url,
  environment.api.changeRequest_url,
  environment.api.contract_url
];

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(@Inject(OKTA_AUTH) private readonly okta: OktaAuth) {}

  public intercept = (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {
    const startsWithRequestUrl = API_URLS.filter((url) => req.url.startsWith(url));
    const isAttachmentUrl = req.url.startsWith(environment.api.attachment_url);
    return startsWithRequestUrl.length > 0
      ? of(this.okta.getAccessToken()).pipe(
          switchMap((accessToken) =>
            accessToken
              ? isAttachmentUrl
                ? next.handle(
                    req.clone({
                      headers: req.headers
                        .set('Accept', 'application/json, application/xml')
                        .set('Authorization', `Bearer ${accessToken}`)
                    })
                  )
                : next.handle(
                    req.clone({
                      headers: req.headers
                        .set('Accept', 'application/json, application/xml')
                        .set('Content-Type', 'application/json')
                        .set('Authorization', `Bearer ${accessToken}`)
                    })
                  )
              : throwError('No AcessToken found, aborting request.')
          )
        )
      : next.handle(req);
  };
}
