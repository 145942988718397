import { Component, OnInit, OnDestroy } from '@angular/core';
import { STATIC_CONTENT_PAYLOAD, getStaticContent, IconName, IconColor } from '@inmarsat-itcloudservices/ui';
import { staticContent } from '@app/app.constants';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { getOutboundFeedsByARAccount } from '@app/shared-store/account/account.selectors';
import { Store } from '@ngrx/store';
import { IState } from '@app/shared-store';
import { IOutboundFeed, OutboundFeedStatus, MandatoryOutboundFeed } from '@app/shared/models/outbound-feed.model';
import { getOutboundFeedDetails } from '@app/shared-store/outbound-feed/outbound-feed.selectors';
import * as OutboundFeedActions from '@shared-store/outbound-feed/outbound-feed.actions';

const DEACTIVATE_MODAL = getStaticContent('outbound-feed.deactivate_inherited_obf', staticContent);

@Component({
  selector: 'inm-deactivate-outbound-feed',
  templateUrl: './deactivate-outbound-feed.component.html',
  styleUrls: ['./deactivate-outbound-feed.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class DeactivateOutboundFeedComponent implements OnInit, OnDestroy {
  constructor(
    private readonly store: Store<IState>,
    public bsModalRef: BsModalRef
  ) {
    this.subscription.add(
      this.store.select(getOutboundFeedsByARAccount).subscribe((ARAccountOBFs) => {
        if (ARAccountOBFs.length) {
          this.BPAccountNumber = ARAccountOBFs.length;
        }
      })
    );
  }

  private readonly subscription = new Subscription();

  public BPAccountNumber = 0;

  public options = {
    title: DEACTIVATE_MODAL.title,
    message: DEACTIVATE_MODAL.message,
    confirmLabel: DEACTIVATE_MODAL.deactivate_inherited_obfs,
    cancelLabel: DEACTIVATE_MODAL.deactivate_current_one,
    allowCancel: true,
    showConfirmCheckbox: true,
    closeIcon: true,
    closeIconTooltip: DEACTIVATE_MODAL.tooltip
  };

  public allowCancel = true;

  public isConfirmed = false;

  public outboundFeedDetails: IOutboundFeed;

  public readonly iconNames = IconName;

  public readonly iconColors = IconColor;

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getOutboundFeedDetails).subscribe((outboundFeedDetails) => {
        if (outboundFeedDetails && outboundFeedDetails.outboundFeedDetails) {
          this.outboundFeedDetails = outboundFeedDetails.outboundFeedDetails;
        }
      })
    );
  }

  public toggleConfirm(): void {
    this.isConfirmed = !this.isConfirmed;
  }

  public getConfirmMessage(): string {
    return this.isMandatoryOutboundFeed()
      ? getStaticContent(
          'outbound-feed.deactivate_inherited_obf.confirm_checkbox_mandatory_outboundFeeds',
          staticContent
        ).replace('{BPAccountNumber}', this.BPAccountNumber)
      : getStaticContent(
          'outbound-feed.deactivate_inherited_obf.confirm_checkbox_non_mandatory_outboundFeeds',
          staticContent
        ).replace('{BPAccountNumber}', this.BPAccountNumber);
  }

  public deactivateCurrentOBF(): void {
    const payload = {
      _id: this.outboundFeedDetails._id,
      status: OutboundFeedStatus.INACTIVE
    };
    this.store.dispatch(OutboundFeedActions.updateOutboundFeed(payload, false, true, false));
  }

  public deactivateAll(): void {
    const payload = {
      _id: this.outboundFeedDetails._id,
      status: OutboundFeedStatus.INACTIVE
    };
    this.store.dispatch(OutboundFeedActions.updateOutboundFeed(payload, true, true, false));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isMandatoryOutboundFeed(): boolean {
    return (
      this.outboundFeedDetails.feedFormat === MandatoryOutboundFeed.CREDIT_NOTE ||
      this.outboundFeedDetails.feedFormat === MandatoryOutboundFeed.INVOICE
    );
  }
}
